<template>
  <div class="kols-page" :class="{ 'right-aligned': rightAligned }">
    <PagesNewHomeCommonContainer class="title-container">
      <PagesNewHomeCommonAnimationBoxShowOneByOne
        :transform="30"
        :ani-show-top="200"
        :ani-hide-top="0"
        :disable-animation="disableAnimation"
      >
        <div class="title-area delay-1" :class="{ 'center-title': centerTitle }">
          <h2 class="title2">{{ title }}</h2>
          <div class="scroll-btn-area" :class="{ 'hide-buttons': hideButtons }">
            <button :class="['btn', 'to-left', { 'btn-show': kolShowLeftBtn }]" @click="kolToLeft"><Arrow /></button>
            <button :class="['btn', 'to-right', { 'btn-show': kolShowRightBtn }]" @click="kolToRight">
              <Arrow />
            </button>
          </div>
        </div>
      </PagesNewHomeCommonAnimationBoxShowOneByOne>
    </PagesNewHomeCommonContainer>

    <PagesNewHomeCommonContainer class="content-area kols-container">
      <div class="kols-area">
        <PagesNewHomeCommonAnimationBoxShowOneByOne
          :transform="30"
          :ani-show-top="200"
          :ani-hide-top="0"
          :disable-animation="disableAnimation"
        >
          <div ref="kolScrollOutRef" class="kols-scroll-area">
            <div ref="kolScrollInnerRef" class="kol-list">
              <div v-for="(item, index) in getKolList" :key="index" class="card-box">
                <PagesNewHomeKOLCard
                  ref="kolCardRef"
                  :class="['card-item', `delay-${index + 2}`]"
                  :data="item"
                  @mouseover="mouseOver(index)"
                />
              </div>
            </div>
          </div>
        </PagesNewHomeCommonAnimationBoxShowOneByOne>
      </div>
    </PagesNewHomeCommonContainer>
  </div>
</template>

<script lang="ts" setup>
import { ResizeSensor } from "css-element-queries"
import { Breakpoints, getKolsData } from "~/constant/pages/newHome"
import Arrow from "@/assets/images/pages/newHome/home-simplify-life-arrow.svg"
import { useTranslateI18n, useI18nCountry } from "~~/composables/store/i18n-country"

const path = useRoute().path
const kolsData = getKolsData(useIsLandingByLanguage(path))

const kolScrollOutRef = ref()
const kolScrollInnerRef = ref()
const { x: kolX } = useScroll(kolScrollOutRef, { behavior: "smooth" })
const kolCardRef = ref()

const boxHoverWidth = ref(300)

// 定义组件的 props
const props = defineProps({
  rightAligned: {
    type: Boolean,
    default: false
  },
  centerTitle: {
    type: Boolean,
    default: false
  },
  hideButtons: {
    type: Boolean,
    default: false
  },
  useNewStyle: {
    type: Boolean,
    default: false
  },
  disableAnimation: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: () => useTranslateI18n("newHome.community.title2")
  }
})

const kolShowLeftBtn = computed(() => {
  return kolX.value > 0
})

const kolShowRightBtn = computed(() => {
  return kolX.value + kolScrollOutRef.value?.offsetWidth < kolScrollInnerRef.value?.offsetWidth
})

const kolToLeft = () => {
  if (kolShowLeftBtn.value) {
    kolX.value -= kolScrollOutRef.value.offsetWidth / 2
  }
}

const kolToRight = () => {
  if (kolShowRightBtn.value) {
    kolX.value += kolScrollOutRef.value.offsetWidth / 2
  }
}

const getKolList = computed(() => {
  return kolsData[useI18nCountry().value]
})

const mouseOver = async (index: number) => {
  const leftDuring = kolCardRef.value[index].kolCardRef.offsetLeft - kolX.value
  if (leftDuring < 0) {
    kolX.value += leftDuring
    return
  }
  const rightDuring =
    kolCardRef.value[index].kolCardRef.offsetLeft +
    boxHoverWidth.value -
    (kolX.value + kolScrollOutRef.value.offsetWidth)
  if (rightDuring > 0) {
    kolX.value += rightDuring
    return
  }
}

const breakPointList = useBreakpoints(Breakpoints) as any

const getResizeSensor = () => {
  nextTick(() => {
    if (breakPointList.$1920.value) {
      boxHoverWidth.value = props.rightAligned ? 380 : 330
    } else if (breakPointList.$1400.value) {
      boxHoverWidth.value = props.rightAligned ? 380 : 330
    } else if (breakPointList.$900.value) {
      boxHoverWidth.value = props.rightAligned ? 380 : 200
    } else if (breakPointList.$600.value) {
      boxHoverWidth.value = props.rightAligned ? 200 : 200
    } else {
      boxHoverWidth.value = props.rightAligned ? 237.5 : 200
    }
  })
}

onMounted(() => {
  const body = document.getElementsByTagName("body")[0]
  new ResizeSensor(body, () => {
    getResizeSensor()
  })
})
</script>

<style lang="scss" scoped src="./index.scss" />
<style lang="scss" scoped>
.kols-area {
  --kol-card-hover-width: calc(v-bind(boxHoverWidth) * 1px);
}
</style>
