<template>
  <div
    ref="kolCardRef"
    class="kol-card card-item"
    :class="{ 'has-play-btn': data.link }"
    @mouseover="$emit('mouseover')"
  >
    <div class="bg-area">
      <CommonImage
        class="bg"
        :src="data.img"
        :lazy="true"
        :alt="props.data.alt"
        background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
      />
      <div class="cover" />
      <div class="linear" />
    </div>
    <div class="padding-area">
      <div class="content-area">
        <h3 class="name">{{ data.name }}</h3>
        <p class="fans-num" v-if="data.fansNum">
          <FunsUserIcon />
          <span>{{ data.fansNum }} k</span>
        </p>
        <p class="desc">{{ data.desc }}</p>
      </div>
    </div>
    <div class="play-area" v-if="data.link">
      <CommonImage
        class="play-icon"
        :src="getCloudAssets('/images/pages/newHome/home-community-play-icon.png')"
        :lazy="true"
        background="linear-gradient(108deg, #aaa 25%, #e1e1e1 37%, #aaa 63%)"
        @click.stop="playVideo"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import FunsUserIcon from "~/assets/icons/pages/landing/funs.svg"
import { KolsSayType } from "~/types/pages/newHome"

interface IProps {
  data: KolsSayType
}

const props = withDefaults(defineProps<IProps>(), {
  data: () => ({
    img: "",
    name: "",
    desc: "",
    link: "",
    alt: ""
  })
})

const {} = toRefs(props)

const kolCardRef = ref()

const playVideo = () => {
  useHtmlDialog(props.data.link)
}

onMounted(() => {})

defineExpose({
  kolCardRef
})
</script>

<style lang="scss" scoped src="./index.scss" />
